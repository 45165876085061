import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  noGap?: boolean;
  className?: string;
}

const Grid = ({
  children,
  fullWidth = false,
  noGap = false,
  className
}: IProps) => (
  <div
    className={cn(styles.grid, className, {
      [styles.noPadding]: fullWidth,
      [styles.noGap]: noGap
    })}
  >
    {children}
  </div>
);
export default Grid;
