const fragmentShader = `
varying vec2 vUv;
uniform vec3 shaderColorRing;
uniform vec3 shaderColorEdge;
uniform vec3 shaderBackground;

void main() {
  vec4 ringColor = vec4(shaderColorRing.rgb, 1.0);
  vec4 edgeColor = vec4(shaderColorEdge.rgb, 1.0);
  vec4 bgColor = vec4(shaderBackground.rgb, 1.0);

  vec2 circlePos = vec2(0.5, 0.5);
  float dist = distance(vUv, circlePos);

  // Increase the size of the transparent area by adjusting the smoothstep parameters
  float centerToRingTransition = smoothstep(0.0, 0.25, dist);
  vec4 color1 = mix(vec4(0.0, 0.0, 0.0, 0.0), ringColor, centerToRingTransition);

  float ringToEdgeTransition = smoothstep(0.275, 0.5, dist);
  vec4 color2 = mix(color1, edgeColor, ringToEdgeTransition);

  // The final color will use the alpha from color2, which is 0 in the center and 1 at the ring
  gl_FragColor = vec4(color2.rgb, color2.a);
}
`;

export default fragmentShader;
