// extracted by mini-css-extract-plugin
export var container = "styles-module--container--79409";
export var desktop = "1440px";
export var giant = "2200px";
export var graph = "styles-module--graph--bc9d4";
export var graphDesktop = "styles-module--graphDesktop--50609";
export var graphMobile = "styles-module--graphMobile--20109";
export var graphTablet = "styles-module--graphTablet--611ac";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";