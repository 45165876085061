// extracted by mini-css-extract-plugin
export var buttonDesktop = "styles-module--buttonDesktop--880df";
export var buttonMobile = "styles-module--buttonMobile--11d6c";
export var buttonTablet = "styles-module--buttonTablet--7572e";
export var chevron = "styles-module--chevron--c0319";
export var comingsoon = "styles-module--comingsoon--06ed5";
export var compact = "styles-module--compact--64124";
export var completed = "styles-module--completed--efdee";
export var container = "styles-module--container--db5b3";
export var descriptionDesktop = "styles-module--descriptionDesktop--cf45c";
export var descriptionMobile = "styles-module--descriptionMobile--c365b";
export var desktop = "1440px";
export var expanded = "styles-module--expanded--02b36";
export var eyebrow = "styles-module--eyebrow--197c3";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var live = "styles-module--live--a8d1a";
export var mobile = "400px";
export var onlineStatus = "styles-module--onlineStatus--e9a73";
export var pending = "styles-module--pending--394b8";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var status = "styles-module--status--8ec5e";
export var statuses = "styles-module--statuses--5843e";
export var tablet = "768px";
export var title = "styles-module--title--1d1e7";
export var touchContent = "styles-module--touchContent--d0dbe";
export var touchContentContainer = "styles-module--touchContentContainer--770d7";