// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--ab61b";
export var appearDown = "styles-module--appear-down--809a5";
export var appearLeft = "styles-module--appear-left--9e722";
export var appearRight = "styles-module--appear-right--c0bdd";
export var appearUp = "styles-module--appear-up--ddf82";
export var borderGrow = "styles-module--border-grow--a8352";
export var borderGrowHorizontal = "styles-module--border-grow-horizontal--34a62";
export var container = "styles-module--container--1b805";
export var desktop = "1440px";
export var floating = "styles-module--floating--cfc34";
export var floatingMobile = "styles-module--floating-mobile--3784d";
export var giant = "2200px";
export var headingAppear = "styles-module--heading-appear--76be0";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var links = "styles-module--links--6d6f6";
export var linksGroup = "styles-module--linksGroup--25919";
export var logoContainer = "styles-module--logoContainer--fa59c";
export var logomark = "styles-module--logomark--2a44a";
export var marqueeHorizontal = "styles-module--marquee-horizontal--826f0";
export var marqueeHorizontalAppear = "styles-module--marquee-horizontal-appear--e9d23";
export var marqueeVertical = "styles-module--marquee-vertical--3605e";
export var marqueeVerticalAppear = "styles-module--marquee-vertical-appear--92a32";
export var mediaAppear = "styles-module--media-appear--88ba2";
export var mobile = "400px";
export var onGrid = "styles-module--onGrid--ac090";
export var siteMetadata = "styles-module--siteMetadata--eb71a";
export var siteMetadataTablet = "styles-module--siteMetadataTablet--ca64e";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var social = "styles-module--social--5e3f3";
export var socials = "styles-module--socials--c7b46";
export var socialsMobile = "styles-module--socialsMobile--3eaa2";
export var tablet = "768px";