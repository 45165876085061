import React, { useEffect, useRef } from "react";
import cn from "classnames";
import { SVG } from "~components";
import * as styles from "./styles.module.scss";

interface IProps {
  title?: string;
  className?: string;
  children: React.ReactNode;
  isVisible: boolean;
  onClose: () => void;
  disableOutsideClick?: boolean;
  hideHeader?: boolean;
}

const Modal = ({
  title,
  className,
  children,
  isVisible,
  onClose,
  disableOutsideClick = false,
  hideHeader = false,
}: IProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !disableOutsideClick &&
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, disableOutsideClick, onClose]);

  useEffect(() => {
    if (isVisible) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.removeProperty("overflow");
    }
    return () => {
      document.documentElement.style.removeProperty("overflow");
    };
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div className={cn(styles.modalWrapper, className)}>
      <div className={styles.modal} ref={modalRef}>
        {!hideHeader && (
          <header className={cn(styles.header, { [styles.hasTitle]: !!title })}>
            {title && <h2 className={cn(styles.title, "h2")}>{title}</h2>}
            <button type="button" onClick={onClose} className={styles.closeButton}>
              <span className={styles.visuallyHidden}>Close</span>
              <SVG className={styles.closeSvg} svg="x" />
            </button>
          </header>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;