// extracted by mini-css-extract-plugin
export var body = "styles-module--body--8dd78";
export var bodyContainer = "styles-module--bodyContainer--34ca6";
export var buttonContainer = "styles-module--buttonContainer--ea46c";
export var container = "styles-module--container--b8408";
export var desktop = "1440px";
export var eyebrow = "styles-module--eyebrow--c2f7a";
export var giant = "2200px";
export var grid = "styles-module--grid--26553";
export var gridContainer = "styles-module--gridContainer--401d3";
export var image = "styles-module--image--6ea20";
export var imageContainer = "styles-module--imageContainer--71c47";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subtitle = "styles-module--subtitle--4c503";
export var tablet = "768px";
export var title = "styles-module--title--e1fbe";