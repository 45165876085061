import React from "react";
import Colors from "./Colors";
import Fonts from "./Fonts";
import HeightDetector from "./HeightDetector";

const Theme = () => (
  <>
    <Colors />
    <Fonts />
    {/* <HeightDetector /> */}
  </>
);

export default Theme;
