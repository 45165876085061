// extracted by mini-css-extract-plugin
export var border = "styles-module--border--b75c0";
export var borderGrow = "styles-module--border-grow--207d4";
export var container = "styles-module--container--d272a";
export var dark = "styles-module--dark--0fa58";
export var desktop = "1440px";
export var giant = "2200px";
export var headingAppear = "styles-module--heading-appear--5846c";
export var headingCrop = "styles-module--headingCrop--10380";
export var headingLower = "styles-module--headingLower--a1d5b";
export var headingUpper = "styles-module--headingUpper--22f96";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--4bcad";
export var mediaAppear = "styles-module--media-appear--36660";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var text = "styles-module--text--2187e";