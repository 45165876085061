// extracted by mini-css-extract-plugin
export var active = "styles-module--active--e2999";
export var container = "styles-module--container--a5403";
export var dark = "styles-module--dark--bf45a";
export var desktop = "1440px";
export var giant = "2200px";
export var grid = "styles-module--grid--21957";
export var hamburger = "styles-module--hamburger--ba835";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--7d882";
export var line = "styles-module--line--d883c";
export var logoDesktop = "styles-module--logoDesktop--68233";
export var mobile = "400px";
export var navbar = "styles-module--navbar--225ed";
export var navbarDesktop = "styles-module--navbarDesktop--7d0b5";
export var navbarLogo = "styles-module--navbarLogo--33ee0";
export var navbarTouch = "styles-module--navbarTouch--d5e0e";
export var open = "styles-module--open--7defd";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var touchLink = "styles-module--touchLink--3c26e";
export var touchNav = "styles-module--touchNav--20a85";
export var touchNavContainer = "styles-module--touchNavContainer--8e889";
export var touchSocial = "styles-module--touchSocial--cb698";
export var touchSocials = "styles-module--touchSocials--b6c14";