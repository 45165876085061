import React, { Suspense } from "react";
import cn from "classnames";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    title: string;
    sliceConfig: ISliceConfig;
  };
}

const Hero = ({ data: { title, sliceConfig } }: IProps) => {
  let subheading;
  let tagline;
  let mediaJSX = null;

  return (
    <section className={styles.container}>
      <div className={styles.text}>{title}</div>
    </section>
  );
};

export default Hero;
