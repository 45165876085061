// extracted by mini-css-extract-plugin
export var button = "styles-module--button--be9c9";
export var container = "styles-module--container--aba13";
export var content = "styles-module--content--fe4cc";
export var desktop = "1440px";
export var giant = "2200px";
export var hoveredOverlay = "styles-module--hoveredOverlay--66a7b";
export var image = "styles-module--image--b09fd";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var overlay = "styles-module--overlay--2d1bd";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var title = "styles-module--title--7e182";