import React from "react";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "~hooks";

interface IProps {
  pageTitle: string;
  noIndex: boolean;
  seo?: {
    pageTitle?: string;
    description?: string;
    image?: {
      asset: {
        url: string;
      };
    };
  };
}

const SEO = ({ pageTitle, seo, noIndex = false }: IProps) => {
  const meta = useSiteMetadata();
  const siteTitle = meta.title;
  const siteDescription = meta?.description;
  const siteImage = meta?.banner;
  const siteUrl = meta?.siteUrl;
  const siteLanguage = meta?.ogLanguage;
  const twitter = meta?.twitter;

  const pageSeo = {
    title: pageTitle === `Home` ? siteTitle : seo?.pageTitle || pageTitle,
    description: seo?.description || siteDescription,
    image: seo?.image?.asset?.url || `${siteUrl}${siteImage}`,
    language: siteLanguage,
    url: siteUrl
  };

  const titleText =
    pageTitle === `Home` ? pageSeo?.title : `${pageSeo?.title} | ${siteTitle}`;

  return (
    <>
      <Helmet>
        {noIndex && <meta name="robots" content="noindex" />}
        <title>{titleText}</title>
        {pageSeo?.description && (
          <meta name="description" content={pageSeo.description} />
        )}
        {pageSeo?.description && (
          <meta property="og:description" content={pageSeo.description} />
        )}

        {pageSeo?.image && <meta name="image" content={pageSeo.image} />}
        <link rel="icon" type="image/x-icon" href="/images/favicon.ico" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* facebook */}
        {siteTitle && <meta property="og:site_name" content={siteTitle} />}
        <meta property="og:locale" content={pageSeo?.language} />
        <meta property="og:url" content={pageSeo?.url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageSeo?.title} />
        <meta property="og:description" content={pageSeo?.description} />
        <meta property="og:image" content={pageSeo?.image} />
        <meta property="og:image:alt" content={pageSeo?.description} />

        {/* twitter */}
        {twitter && <meta name="twitter:creator" content={twitter} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageSeo?.title} />
        <meta name="twitter:description" content={pageSeo?.description} />
        <meta name="twitter:image" content={pageSeo?.image} />
        <meta name="twitter:image:alt" content={pageSeo?.description} />
      </Helmet>
    </>
  );
};

export default SEO;
