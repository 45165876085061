import React from "react";
import cn from "classnames";
import {
  IAltImage,
  ILinkExternal,
  ILinkInternal,
  ISliceConfig,
  IVideo
} from "~schemas";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button, HTMLVideo, Grid, SliceConfig } from "~components";
import { useBreakpoints } from "~hooks";
import { getButtonProps } from "~utils";

import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    eyebrow?: string;
    title?: string;
    body?: string;
    buttonLink: Array<ILinkExternal | ILinkInternal>;
    media: Array<IAltImage | IVideo>;
    sliceConfig?: ISliceConfig;
  };
  isInCarousel?: boolean;
}

const Media = ({
  data: { eyebrow, title, body, buttonLink, media, sliceConfig },
  isInCarousel
}: IProps) => {
  const { breakpoints } = useBreakpoints();

  const isTablet = breakpoints.tablet;

  const mediaData = media?.[0];
  const button = buttonLink?.[0];
  const buttonProps = getButtonProps(button);

  const isAltImage = (media: IAltImage | IVideo): media is IAltImage => {
    return media._type === "altImage";
  }

  const isVideo = (media: IAltImage | IVideo): media is IVideo => {
    return media._type === "video";
  }

  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <Grid className={styles.grid}>
        {eyebrow && <p className={cn("label", styles.eyebrow)}>{eyebrow}</p>}

        {title && (
          <div className={cn(styles.titleWrapper, isInCarousel && styles.inCarousel)}>
            <h3 className="d1">{title}</h3>

            {button?.title && (
              <Button
                className={styles.buttonTablet}
                {...buttonProps}
                variant="secondary"
                inheritColor
              >
                {button?.title}
              </Button>
            )}
          </div>
        )}

        <div className={cn(styles.bodyWrapper, isInCarousel && styles.inCarousel)}>
          {body && <p className={cn("b1", !isInCarousel && styles.body)}>{body}</p>}

          {button?.title && (
            <Button
              className={styles.buttonBody}
              {...buttonProps}
              variant="secondary"
              inheritColor
            >
              {button?.title}
            </Button>
          )}
        </div>

        {mediaData && (
          <figure className={styles.media}>
            {isVideo(mediaData) && (
              <HTMLVideo
                src={isTablet || !mediaData.touchUrl ? mediaData.url : mediaData.touchUrl}
                poster={mediaData.poster?.asset.url}
                playsInline
                autoPlay
                muted
                loop
              />
            )}

            {isAltImage(mediaData) && (
              <GatsbyImage
                style={{
                  width: `100%`,
                  height: `100%`,
                  borderRadius: `0.75rem`
                }}
                alt={mediaData.altText || "Lucidao"}
                image={mediaData.asset.gatsbyImageData}
              />
            )}
          </figure>
        )}
      </Grid>
    </SliceConfig>
  );
};

export default Media;
