import React from "react";
import { Grid, SliceConfig, SVG } from "~components";
import * as styles from "./styles.module.scss";
import { ISliceConfig } from "~schemas";

interface IProps {
  data: {
    sliceConfig: ISliceConfig;
  };
}

const Governance = ({ data: { sliceConfig } }: IProps) => {
  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <Grid>
        <figure className={styles.graph}>
          <SVG className={styles.graphMobile} svg="proposalGraphMobile" />

          <SVG className={styles.graphTablet} svg="proposalGraphDesktop" />

          <SVG className={styles.graphDesktop} svg="proposalGraphDesktop" />
        </figure>
      </Grid>
    </SliceConfig>
  );
};

export default Governance;
