import React, { Suspense, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import cn from "classnames";
import { Button, Grid, SpotlightMesh, SocialLink, SVG } from "~components";

import { ILinkExternal, ILinkInternal } from "~schemas";
import { getButtonProps } from "~utils";

import { Canvas } from "@react-three/fiber";
import { useWindowDimensions } from "~hooks";

import * as styles from "./styles.module.scss";

interface IProps {
  className?: string;
}

interface IStaticQuery {
  sanitySettings: {
    footer: {
      clientName: string;
      version: string;
      footerLinks: Array<{
        _key: string;
        links: Array<ILinkInternal | ILinkExternal>;
      }>;
    };
    socials: Array<{
      _key: string;
      title: string;
      url: string;
      newWindow: boolean;
    }>;
  };
}

const query = graphql`
  query FooterQuery {
    sanitySettings {
      footer {
        clientName
        version
        footerLinks {
          _key
          links {
            ... on SanityLinkExternal {
              _key
              _type
              title
              url
            }
            ... on SanityLinkInternal {
              _key
              _type
              title
              reference {
                slug {
                  current
                }
              }
            }
          }
        }
      }
      socials {
        _key
        title
        url
        newWindow
      }
    }
  }
`;

const Footer = ({ className }: IProps) => {
  const {
    sanitySettings: { footer, socials }
  } = useStaticQuery<IStaticQuery>(query);

  const { clientName, version, footerLinks } = footer;

  const { windowSize } = useWindowDimensions();

  const canvasRef = useRef<HTMLDivElement>(null);
  const ringColor = `#E7E9F3`;
  const edgeColor = `#E7E9F3`;
  const backgroundColor = `#000000`;

  return (
    <footer className={cn([styles.container, className])}>
      <Grid>
        <article className={styles.siteMetadata}>
          {clientName && <p className="button">{clientName}</p>}

          {version && <p className="button">{version}</p>}
        </article>

        {/* content tablet */}
        <div className={styles.siteMetadataTablet}>
          <article>
            {clientName && <p className="button">{clientName}</p>}

            {version && <p className="button">{version}</p>}
          </article>

          {socials?.[0] && (
            <ul className={styles.socials}>
              {socials.map(({ _key, title, newWindow, url }) => {
                let i;

                return (
                  <li key={`footer-social-${_key}`}>
                    <SocialLink
                      className={styles.social}
                      title={title}
                      newWindow={newWindow}
                      url={url}
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        {footerLinks?.[0] && (
          <div className={styles.links}>
            {footerLinks.map(({ _key, links }) => {
              return (
                <ul key={`footer-links-${_key}`} className={styles.linksGroup}>
                  {links?.map((link) => {
                    const { _key, title } = link;
                    const buttonProps = getButtonProps(link);

                    return (
                      <li key={`footer-link-${_key}`}>
                        <Button {...buttonProps} variant="text">
                          {title}
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
          </div>
        )}

        {/* socials mobile */}
        {socials?.[0] && (
          <ul className={cn(styles.socials, styles.onGrid)}>
            {socials.map(({ _key, title, newWindow, url }) => {
              return (
                <li key={`footer-social-${_key}`}>
                  <SocialLink
                    className={styles.social}
                    title={title}
                    newWindow={newWindow}
                    url={url}
                  />
                </li>
              );
            })}
          </ul>
        )}

        <div ref={canvasRef} className={styles.logoContainer}>
          <figure className={styles.logomark}>
            <SVG svg="wordmarkSwoosh" />
          </figure>

          {/* noise canvas */}
          {canvasRef?.current && (
            <Canvas
              orthographic
              shadows
              camera={{ zoom: 1, position: [0, 0, 1] }}
              style={{
                position: `absolute`,
                top: 0,
                left: 0,
                background: "transparent"
              }}
            >
              <ambientLight intensity={0.5} />

              <pointLight
                position={[0, 10, 30]}
                rotation={[0, 90, 0]}
                intensity={1}
                shadow-mapSize-height={512}
                shadow-mapSize-width={512}
                castShadow
              />

              <Suspense>
                <SpotlightMesh
                  canvas={canvasRef.current}
                  dimensions={windowSize}
                  ringColor={ringColor}
                  edgeColor={edgeColor}
                  backgroundColor={backgroundColor}
                />
              </Suspense>
            </Canvas>
          )}
        </div>
      </Grid>
    </footer>
  );
};

export default Footer;
