import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import { Grid, Link, SocialLink, SVG, Tab } from "~components";
import { graphql, useStaticQuery } from "gatsby";
import { ILinkExternal } from "~schemas";
import { useApp } from "~hooks";
import * as styles from "./styles.module.scss";

interface IProps {
  className?: string;
  location: {
    pathname: string;
  };
}

interface IStaticQuery {
  sanitySettings: {
    header: {
      _id: string;
      mode: string;
      links: Array<{
        _id: string;
        title: string;
        slug: {
          current: string;
        };
      }>;
    };
    socials: Array<ILinkExternal>;
  };
}

const query = graphql`
  query HeaderQuery {
    sanitySettings {
      header {
        mode
        links {
          _id
          title
          slug {
            current
          }
        }
      }
      socials {
        _key
        title
        url
        newWindow
      }
    }
  }
`;

const Header = ({ className, location }: IProps) => {
  const {
    sanitySettings: { header, socials }
  } = useStaticQuery<IStaticQuery>(query);

  const { mode, links } = header;

  const { pathname } = useApp();
  const touchNavRef = useRef(null);

  const [touchNavHeight, setTouchNavHeight] = useState(0);
  const [active, setActive] = useState(false);

  const isActive = (slug: string) => {
    let active;
    if (slug === "/") {
      active = location?.pathname === "/";
    } else {
      active = location?.pathname === `/${slug}/`;
    }

    return active;
  };

  const calculateContentHeight = () => {
    const height = touchNavRef?.current?.offsetHeight;
    setTouchNavHeight(height);
  };

  useEffect(() => {
    if (active) {
      setActive(false);
    }
  }, [pathname]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(calculateContentHeight);
    if (touchNavRef.current) {
      resizeObserver.observe(touchNavRef.current);
    }
    return () => {
      if (touchNavRef.current) {
        resizeObserver.unobserve(touchNavRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => setActive(false);
    document.addEventListener(`scroll`, handleScroll, {
      passive: true
    });
    return () =>
      document.removeEventListener(`scroll`, handleScroll, {
        passive: true
      });
  }, []);

  return (
    <header className={cn(styles.container, className, styles[mode])}>
      <Grid className={styles.grid}>
        <Link className={styles.logoDesktop} to="/">
          <SVG svg="wordmarkSwoosh" />
        </Link>

        <ul className={cn(styles.navbarDesktop, styles[mode])}>
          {links?.[0] &&
            links.map(({ _id, title, slug }) => {
              const active = isActive(slug?.current);
              return (
                <li key={`navbar-desktop-link-${_id}`}>
                  <Tab to={`/${slug?.current}`} active={active} lightTheme>
                    {title}
                  </Tab>
                </li>
              );
            })}
        </ul>

        <div
          className={cn(styles.navbarTouch, styles[mode], {
            [styles.active]: active
          })}
        >
          <div className={styles.navbar}>
            <Link
              className={styles.navbarLogo}
              to="/"
              title="LUCIDAO"
              ariaLabel="LUCIDAO"
            >
              <SVG svg="wordmarkSwoosh" />
            </Link>

            <button
              type="button"
              title={active ? `Close navigation` : `Open navigation`}
              aria-label={active ? `Close navigation` : `Open navigation`}
              onClick={() => setActive((prev) => !prev)}
              className={cn(styles.hamburger, { [styles.open]: active })}
            >
              <div className={styles.line} />
              <div className={styles.line} />
              <div className={styles.line} />
            </button>
          </div>

          <div
            className={cn(styles.touchNavContainer, {
              [styles.open]: active
            })}
            style={{ height: `${active ? touchNavHeight : 0}px` }}
          >
            <nav ref={touchNavRef} className={styles.touchNav}>
              <ul>
                {links?.[0] &&
                  links.map(({ _id, title, slug }) => {
                    const active = isActive(slug?.current);
                    return (
                      <li key={`navbar-touch-link-${_id}`}>
                        <Link
                          className={cn("h1", styles.touchLink, {
                            [styles.active]: active
                          })}
                          title={title}
                          to={`/${slug?.current}`}
                        >
                          {title}
                        </Link>
                      </li>
                    );
                  })}
              </ul>

              {socials?.[0] && (
                <ul className={styles.touchSocials}>
                  {socials.map(({ _key, title, url, newWindow }) => {
                    return (
                      <li
                        key={`navbar-social-link-${_key}`}
                        className={styles.touchSocial}
                      >
                        <SocialLink
                          title={title}
                          newWindow={newWindow}
                          url={url}
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
            </nav>
          </div>
        </div>
      </Grid>
    </header>
  );
};

export default Header;
