import React from "react";
import cn from "classnames";
import { Grid, SliceConfig } from "~components";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    text: string;
    sliceConfig: ISliceConfig;
  };
}

const TextSpotlight = ({ data: { text, sliceConfig } }: IProps) => {
  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <Grid>
        <div className={styles.content}>
          <h2 className={cn(`h1`, styles.text)}>{text}</h2>
        </div>
      </Grid>
    </SliceConfig>
  );
};

export default TextSpotlight;
