import React from "react";
import cn from "classnames";
import { IAltImage, ILinkExternal, ILinkInternal } from "~schemas";
import { Button, Link } from "~components";
import { GatsbyImage } from "gatsby-plugin-image";
import { getButtonProps } from "~utils";

import * as styles from "./styles.module.scss";

export interface IBlurCard {
  _key: string;
  title: string;
  link: Array<ILinkExternal | ILinkInternal>;
  image: IAltImage;
}

interface IProps {
  data: IBlurCard;
  className?: string;
}

const BlurCard = ({ data, className }: IProps) => {
  const { image, link, title } = data || {};

  const button = link?.[0];
  const buttonProps = getButtonProps(button);

  // todo: on desktop maybe make the container a div instead of a link
  // and re-enable the button to/href props
  return (
    <Link
      className={cn(styles.container, className)}
      to={buttonProps?.to || buttonProps?.href}
    >
      <div className={styles.overlay} />
      <div className={styles.hoveredOverlay} />

      {image?.asset?.gatsbyImageData && (
        <figure className={styles.image}>
          <GatsbyImage
            style={{
              width: `100%`,
              height: `100%`,
              borderRadius: `0.75rem`
            }}
            alt={image?.altText || "LUCIDAO"}
            image={image?.asset?.gatsbyImageData}
          />
        </figure>
      )}

      <div className={styles.content}>
        <h4 className={cn("h1", styles.title)}>{title}</h4>

        {button && (
          <Button className={styles.button} variant="secondary" lightTheme>
            {button?.title}
          </Button>
        )}
      </div>
    </Link>
  );
};

export default BlurCard;
