// extracted by mini-css-extract-plugin
export var closeButton = "styles-module--closeButton--429b1";
export var closeSvg = "styles-module--closeSvg--f86d7";
export var desktop = "1440px";
export var giant = "2200px";
export var header = "styles-module--header--ee539";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var modal = "styles-module--modal--4926a";
export var modalWrapper = "styles-module--modalWrapper--b7b75";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var title = "styles-module--title--2e998";
export var visuallyHidden = "styles-module--visuallyHidden--690ee";