import React from "react";

// Icon library
import { ReactComponent as arrowDown } from "~assets/svg/arrow-down.svg";
import { ReactComponent as arrowLeft } from "~assets/svg/arrow-left.svg";
import { ReactComponent as arrowRight } from "~assets/svg/arrow-right.svg";
import { ReactComponent as lookingGlass } from "~assets/svg/looking-glass.svg";
import { ReactComponent as mapPin } from "~assets/svg/map-pin.svg";
import { ReactComponent as minus } from "~assets/svg/minus.svg";
import { ReactComponent as playCircle } from "~assets/svg/play-circle.svg";
import { ReactComponent as plus } from "~assets/svg/plus.svg";
import { ReactComponent as tick } from "~assets/svg/tick.svg";
import { ReactComponent as warningTriangle } from "~assets/svg/warning-triangle.svg";
import { ReactComponent as x } from "~assets/svg/x.svg";

// Custom
import { ReactComponent as arrowTopRight } from "~assets/svg/arrow-top-right.svg";
import { ReactComponent as calendar } from "~assets/svg/calendar.svg";
import { ReactComponent as checkCircle } from "~assets/svg/check-circle.svg";
import { ReactComponent as chevronDown } from "~assets/svg/chevron-down.svg";
import { ReactComponent as discord } from "~assets/svg/discord.svg";
import { ReactComponent as hoverEllipse } from "~assets/svg/hover-ellipse.svg";
import { ReactComponent as medium } from "~assets/svg/medium.svg";
import { ReactComponent as polygon } from "~assets/svg/polygon.svg";
import { ReactComponent as proposalGraphDesktop } from "~assets/svg/proposal-graph-desktop.svg";
import { ReactComponent as proposalGraphTablet } from "~assets/svg/proposal-graph-tablet.svg";
import { ReactComponent as proposalGraphMobile } from "~assets/svg/proposal-graph-mobile.svg";
import { ReactComponent as telegram } from "~assets/svg/telegram.svg";
import { ReactComponent as twitter } from "~assets/svg/twitter.svg";
import { ReactComponent as wordmark } from "~assets/svg/wordmark.svg";
import { ReactComponent as wordmarkSecondary } from "~assets/svg/wordmark-secondary.svg";
import { ReactComponent as wordmarkSwoosh } from "~assets/svg/wordmark-swoosh.svg";

const svgs = Object.freeze({
  // Library
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowTopRight,
  calendar,
  checkCircle,
  chevronDown,
  lookingGlass,
  mapPin,
  minus,
  playCircle,
  plus,
  tick,
  warningTriangle,
  x,
  // ... all icons as required
  discord,
  hoverEllipse,
  medium,
  polygon,
  proposalGraphDesktop,
  proposalGraphTablet,
  proposalGraphMobile,
  telegram,
  twitter,
  wordmark,
  wordmarkSecondary,
  wordmarkSwoosh
});

export type TSvg = keyof typeof svgs;

interface IProps {
  svg: TSvg;
  className?: string;
  style?: React.CSSProperties;
}

const SVG = ({ svg, ...props }: IProps) => {
  if (!svgs[svg]) return null;

  const SVGElement = svgs[svg];

  return <SVGElement {...props} aria-hidden />;
};

export default SVG;
