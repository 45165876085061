import React from "react";
import cn from "classnames";
import { Grid, ListItem, SliceConfig } from "~components";
import { IListItem, ISliceConfig } from "~schemas";
import { useBreakpoints } from "~hooks";

import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    eyebrow?: string;
    title?: string;
    body?: string;
    items?: Array<IListItem>;
    sliceConfig: ISliceConfig;
  };
}

const List = ({
  data: { eyebrow, title, body, items, sliceConfig }
}: IProps) => {
  const { breakpoints } = useBreakpoints();
  const isTablet = breakpoints.tablet;

  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <Grid>
        {eyebrow && <p className={cn("label", styles.eyebrow)}>{eyebrow}</p>}
        {title && (
          <h3 className={cn(isTablet ? `h1` : `d1`, styles.title)}>{title}</h3>
        )}
        {body && <p className={cn("b1", styles.body)}>{body}</p>}

        {items?.[0] && (
          <ul className={styles.items}>
            {items.map((item) => {
              return <ListItem key={`list-item-${item?._key}`} data={item} />;
            })}
          </ul>
        )}
      </Grid>
    </SliceConfig>
  );
};

export default List;
