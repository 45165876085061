// extracted by mini-css-extract-plugin
export var accordions = "styles-module--accordions--ea474";
export var active = "styles-module--active--3a9e1";
export var container = "styles-module--container--c16c4";
export var desktop = "1440px";
export var faqsLarge = "styles-module--faqsLarge--6579f";
export var faqsSmall = "styles-module--faqsSmall--5b366";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var topicAccordions = "styles-module--topicAccordions--310f6";
export var topicSelector = "styles-module--topicSelector--40471";
export var topicTitleSmall = "styles-module--topicTitleSmall--aa705";
export var topics = "styles-module--topics--99af7";
export var visible = "styles-module--visible--cdac5";