// extracted by mini-css-extract-plugin
export var blurCard = "styles-module--blurCard--e01bd";
export var body = "styles-module--body--8df30";
export var bodyWrapper = "styles-module--bodyWrapper--9e98a";
export var buttonBody = "styles-module--buttonBody--9b8ef";
export var buttonDesktop = "styles-module--buttonDesktop--c2992";
export var buttonTablet = "styles-module--buttonTablet--cd6b8";
export var cards = "styles-module--cards--38f7d";
export var container = "styles-module--container--4c557";
export var desktop = "1440px";
export var eyebrow = "styles-module--eyebrow--59516";
export var giant = "2200px";
export var grid = "styles-module--grid--c2e59";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var titleWrapper = "styles-module--titleWrapper--b6aca";