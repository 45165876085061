import React from "react";
import cn from "classnames";
import { Grid, SliceConfig, StatCard } from "~components";
import { ISliceConfig, IStat } from "~schemas";

import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    eyebrow?: string;
    title?: string;
    body?: string;
    stats?: Array<IStat>;
    sliceConfig?: ISliceConfig;
  };
}

const Stats = ({
  data: { eyebrow, title, body, stats, sliceConfig }
}: IProps) => {
  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <Grid>
        {eyebrow && <p className={cn("label", styles.eyebrow)}>{eyebrow}</p>}
        {title && <h3 className={cn("h3", styles.title)}>{title}</h3>}
        {body && <p className={cn("b1", styles.body)}>{body}</p>}

        {stats?.[0] && (
          <div className={styles.stats}>
            {stats.map((stat) => {
              return (
                <StatCard
                  key={`stats-section-stat-${stat?._key}`}
                  className={styles.stat}
                  data={stat}
                />
              );
            })}
          </div>
        )}
      </Grid>
    </SliceConfig>
  );
};

export default Stats;
