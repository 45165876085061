import useEmblaCarousel from "embla-carousel-react";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Button, SVG } from "~components";
import { ILinkExternal, ILinkInternal } from "~schemas";
import { getButtonProps } from "~utils";
import { useBreakpoints } from "~hooks";

import * as styles from './styles.module.scss';

interface IPressItem {
  _key: string;
  eyebrow: string;
  description: string;
  links: Array<ILinkInternal | ILinkExternal>;
}

interface IProps {
  data: {
    eyebrow: string;
    title: string;
    description: string;
    links: IPressItem[];
  };
}

const PressCarousel = ({ data }: IProps) => {
  const [carouselRef, carouselApi] = useEmblaCarousel({ loop: true, slidesToScroll: 1, align: 'start' });
  const { breakpoints } = useBreakpoints();

  const isTablet = breakpoints.tablet;
  const { eyebrow, title, description, links } = data;

  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState(0);

  useEffect(() => {
    if (carouselApi) {
      const updateCarousel = (api: typeof carouselApi) => {
        const scrollablePrev = api.canScrollPrev();
        const scrollableNext = api.canScrollNext();

        setCanScrollNext(scrollableNext);
        setCanScrollPrev(scrollablePrev);
        setIsScrollable(scrollableNext || scrollablePrev);
        setSelectedSlide(api.selectedScrollSnap());
      };

      carouselApi.on(`init`, updateCarousel);

      carouselApi.on(`reInit`, updateCarousel);

      carouselApi.on(`resize`, updateCarousel);

      carouselApi.on(`scroll`, updateCarousel);

      updateCarousel(carouselApi);
    }
  }, [carouselApi]);

  return (
    <div className={styles.container}>
      <section className={styles.content}>
        <div className={styles.contentBody}>
          <div>
            {eyebrow && <p className="label">{eyebrow}</p>}
            <h2 className={cn(isTablet ? `h1` : `d1`, styles.title)}>{title}</h2>
          </div>
          <p className="b1 description">{description}</p>
        </div>
        <div>
          {!!isScrollable && (
            <div className={styles.buttons}>
              <Button variant="secondary" disabled={!canScrollPrev} onClick={() => carouselApi?.scrollPrev()}>
                <SVG svg="arrowLeft" />
              </Button>
              <Button variant="secondary" disabled={!canScrollNext} onClick={() => carouselApi?.scrollNext()}>
                <SVG svg="arrowRight" />
              </Button>
            </div>
          )}
          <div className={styles.carouselWrapper}>
            <div className={styles.carousel} ref={carouselRef}>
              <ul className={styles.carouselContainer}>
                {links.map((link) => (
                  <li key={link._key} className={styles.carouselSlide}>
                    <div className={styles.pressItem}>
                      <strong className="caption">{link.eyebrow}</strong>
                      <p className="b1">{link.description}</p>
                      <ul>
                        {link.links.map((button) => {
                          const buttonProps = getButtonProps(button);

                          return (
                            <li key={button._key}>
                              <Button {...buttonProps} variant="primary" inheritColor>{button.title}</Button>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.dots}>
            {links.map((link, index) => (
              <div key={`dots_${link._key}`} className={cn(styles.dot, selectedSlide === index && styles.active)} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PressCarousel;
