const fragmentShader = `
varying vec2 vUv;
uniform float time;
uniform vec2 velocity;
uniform vec3 shaderColorCenter;
uniform vec3 shaderColorRing;
uniform vec3 shaderColorEdge;
uniform vec3 shaderBackground;

void main() {
  vec4 centerColor = vec4(shaderColorCenter.rgb, 1.0);
  vec4 ringColor = vec4(shaderColorRing.rgb, 1.0);
  vec4 edgeColor = vec4(shaderColorEdge.rgb, 1.0);
  vec4 bgColor = vec4(shaderBackground.rgb, 1.0);

  vec2 circlePos = vec2(0.5, 0.5);
  float dist = distance(vUv, circlePos) * (1.0 - length(velocity) * -0.001);
  dist += 0.15;

  float alpha1 = smoothstep(0.2, 0.5, dist);
  vec4 color1 = mix(centerColor, ringColor, alpha1);

  float alpha2 = smoothstep(0.275, 0.5, dist);
  vec4 color2 = mix(color1, vec4(0.0), alpha2);

  // Removed noise generation and application
  vec4 finalColor = color2;

  float edgeFactor = smoothstep(0.1, 0.7, dist);
  finalColor = mix(finalColor, edgeColor, edgeFactor);

  gl_FragColor = mix(finalColor, bgColor, smoothstep(0.5, 0.7, dist));
}
`;

export default fragmentShader;
