import React from "react";
import { PortableTextRenderer } from "~components";
import * as styles from "./styles.module.scss";

interface IProps {
  text?: string;
  rawText?: object;
  as?: string;
  fontType?: "d1" | "d2" | "e1" | "h1" | "h2" | "h3" | "b1" | "b2" | "caption";
  animationDelay: number;
}

const TextCrop = ({
  text,
  rawText,
  as,
  fontType = "d1",
  animationDelay = 0.5
}: IProps) => {
  let Element: any;
  if (as) Element = as;
  else Element = `h1`;

  // force the style of the d1 to be D1
  let formattedText;

  if (rawText) {
    formattedText = {
      ...rawText,
      style: fontType
    };
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.textContainer}
        style={{ animationDelay: `${animationDelay}s` }}
      >
        {formattedText && <PortableTextRenderer rawText={formattedText} />}

        {!rawText && text && (
          <Element className={fontType}>
            <p>{text}</p>
          </Element>
        )}
      </div>
    </div>
  );
};

export default TextCrop;
