// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--8b00d";
export var appearDown = "styles-module--appear-down--1859b";
export var appearLeft = "styles-module--appear-left--7d4b3";
export var appearRight = "styles-module--appear-right--da10f";
export var appearUp = "styles-module--appear-up--68ab7";
export var borderGrow = "styles-module--border-grow--9c6b7";
export var borderGrowHorizontal = "styles-module--border-grow-horizontal--3f90b";
export var container = "styles-module--container--d7c53";
export var content = "styles-module--content--269e2";
export var desktop = "1440px";
export var floating = "styles-module--floating--e5fcb";
export var floatingMobile = "styles-module--floating-mobile--87e36";
export var giant = "2200px";
export var headingAppear = "styles-module--heading-appear--99cf8";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var marqueeHorizontal = "styles-module--marquee-horizontal--d59a9";
export var marqueeHorizontalAppear = "styles-module--marquee-horizontal-appear--ca337";
export var marqueeVertical = "styles-module--marquee-vertical--9d9af";
export var marqueeVerticalAppear = "styles-module--marquee-vertical-appear--4743f";
export var mediaAppear = "styles-module--media-appear--811be";
export var mobile = "400px";
export var preview = "styles-module--preview--8e96c";
export var sidebar = "styles-module--sidebar--75542";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";