// extracted by mini-css-extract-plugin
export var body = "styles-module--body--7ada3";
export var container = "styles-module--container--243b4";
export var desktop = "1440px";
export var giant = "2200px";
export var grid = "styles-module--grid--9ad9c";
export var image = "styles-module--image--747b1";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var title = "styles-module--title--7426a";
export var titles = "styles-module--titles--d2c7f";