// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--2469c";
export var button = "styles-module--button--55bbd";
export var buttonText = "styles-module--buttonText--8cd02";
export var caption = "styles-module--caption--64337";
export var content = "styles-module--content--f35e3";
export var disabled = "styles-module--disabled--77e92";
export var fitContent = "styles-module--fitContent--49329";
export var fluid = "styles-module--fluid--ff499";
export var icon = "styles-module--icon--d808d";
export var inheritColor = "styles-module--inheritColor--f8b6d";
export var left = "styles-module--left--803e3";
export var light = "styles-module--light--659b4";
export var loader = "styles-module--loader--48112";
export var loaderContainer = "styles-module--loaderContainer--ec53c";
export var loading = "styles-module--loading--0d47d";
export var outline = "styles-module--outline--2be72";
export var primary = "styles-module--primary--d3b23";
export var right = "styles-module--right--61b01";
export var secondary = "styles-module--secondary--d4388";
export var spin = "styles-module--spin--d36e5";
export var text = "styles-module--text--214b9";