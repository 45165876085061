import React, { useRef, useEffect, useState } from "react";
import gsap from "gsap";
import { IAltImage } from "~schemas";
import { GatsbyImage } from "gatsby-plugin-image";
import { useBreakpoints, useIntersectionScroll } from "~hooks";

interface IProps {
  data: {
    image: IAltImage;
    imageStyle?: object;
    parallax?: {
      drag?: number;
      boundsPadding?: number;
      xOffset?: number;
      translateFactor?: number;
    };
    loading: "eager" | "lazy";
  };
  className?: string;
}

const ParallaxImage = ({
  data: { image, imageStyle = {}, parallax = {}, loading = `lazy` },
  className
}: IProps) => {
  const {
    drag = 1.25,
    boundsPadding = 10,
    xOffset = 0,
    translateFactor = 12
  } = parallax;

  // ---------------------------------------------------------------------------
  // context / ref / state
  const { breakpoints } = useBreakpoints();
  const isDesktop = breakpoints.desktop;

  const imageRef = useRef<HTMLDivElement>();

  const { ref } = useIntersectionScroll((sectionTop) => {
    if (isDesktop) return;

    gsap.to(imageRef.current, {
      duration: 0,
      x: xOffset,
      y: parseInt(-sectionTop / translateFactor)
    });
  });

  const [pos, setPos] = useState({
    x: 0,
    y: 0,
    centerX: 0,
    centerY: 0
  });

  // ---------------------------------------------------------------------------
  // methods

  const handleMouseMove = (e: MouseEvent) => {
    if (typeof window === `undefined`) return;

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const centerX = e.clientX / viewportWidth - 0.5;
    const centerY = e.clientY / viewportHeight - 0.5;

    setPos({
      x: e.clientX,
      y: e.clientY,
      centerX,
      centerY
    });
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (typeof window === `undefined`) return;

    if (isDesktop) {
      window.addEventListener("mousemove", handleMouseMove, false);
    } else {
      window.removeEventListener("mousemove", handleMouseMove, false);

      if (imageRef?.current) {
        gsap.to(imageRef.current, {
          duration: 1,
          ease: `expo.out`,
          x: 0,
          y: 0
        });
      }
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove, false);
    };
  }, [imageRef, isDesktop]);

  useEffect(() => {
    if (!imageRef.current || !isDesktop) return;

    const { centerX, centerY } = pos;

    const calculatedX = centerX * 2 * boundsPadding * drag;
    const calculatedY = centerY * 2 * boundsPadding * drag;

    const clampedX = Math.max(
      Math.min(calculatedX, boundsPadding),
      -boundsPadding
    );
    const clampedY = Math.max(
      Math.min(calculatedY, boundsPadding),
      -boundsPadding
    );

    gsap.killTweensOf(imageRef.current);

    gsap.to(imageRef.current, {
      duration: 2,
      ease: `expo.out`,
      x: clampedX,
      y: clampedY
    });
  }, [imageRef, pos, breakpoints, isDesktop]);

  return (
    <div ref={ref} className={className}>
      {image?.asset?.gatsbyImageData && (
        <figure ref={imageRef}>
          <GatsbyImage
            style={imageStyle}
            alt={image?.altText || "LUCIDAO"}
            image={image.asset.gatsbyImageData}
            loading={loading}
          />
        </figure>
      )}
    </div>
  );
};

export default ParallaxImage;
