import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import { Button, Grid, SVG } from "~components";
import { useBreakpoints } from "~hooks";
import * as styles from "./styles.module.scss";
import { IListItem } from "~schemas";
import { getButtonProps } from "~utils";

interface IProps {
  data: IListItem;
  className?: string;
}

const ListItem = ({
  data: { eyebrow, title, status, onlineStatus, description, link },
  className
}: IProps) => {
  const { breakpoints } = useBreakpoints();
  const touchContentRef = useRef(null);

  const isTablet = breakpoints.tablet;

  const [touchContentHeight, setTouchContentHeight] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const hasStatuses = status || onlineStatus;

  const linkData = link?.[0] || {};
  const linkProps = getButtonProps(linkData);

  const calculateContentHeight = () => {
    const height = touchContentRef?.current?.offsetHeight;
    setTouchContentHeight(height);
  };

  const onExpand = () => {
    if (isTablet) return;

    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    setExpanded(isTablet);
  }, [isTablet]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(calculateContentHeight);
    if (touchContentRef.current) {
      resizeObserver.observe(touchContentRef.current);
    }
    return () => {
      if (touchContentRef.current) {
        resizeObserver.unobserve(touchContentRef.current);
      }
    };
  }, []);

  return (
    <li className={cn(styles.container, className)}>
      <Grid fullWidth noGap>
        <p className={cn("label", styles.eyebrow)}>{eyebrow}</p>

        <div
          className={cn(styles.title, { [styles.compact]: hasStatuses })}
          onClick={() => onExpand()}
        >
          <p className="h3">{title}</p>

          <SVG
            className={cn(styles.chevron, { [styles.expanded]: expanded })}
            svg="chevronDown"
          />

          {linkData && (
            <div className={styles.buttonTablet}>
              <Button {...linkProps} variant="secondary" inheritColor>
                {linkData?.title}
              </Button>
            </div>
          )}
        </div>

        {(description || linkData) && (
          <div
            className={cn(styles.descriptionDesktop, {
              [styles.compact]: hasStatuses
            })}
          >
            <p className={cn("b2", { [styles.compact]: hasStatuses })}>
              {description}
            </p>

            {linkData && (
              <div className={styles.buttonDesktop}>
                <Button {...linkProps} variant="secondary" inheritColor>
                  {linkData?.title}
                </Button>
              </div>
            )}
          </div>
        )}

        {/* accordion content */}
        <div
          className={styles.touchContentContainer}
          style={{
            height: `${expanded ? touchContentHeight : 0}px`
          }}
        >
          <div ref={touchContentRef} className={styles.touchContent}>
            {description && (
              <p className={cn("b2", styles.descriptionMobile)}>
                {description}
              </p>
            )}

            {(status || onlineStatus) && (
              <div className={styles.statuses}>
                {status && (
                  <div>
                    <p className="label">Status:</p>
                    <p className={cn("b2", styles.status)}>{status}</p>
                  </div>
                )}

                {onlineStatus && (
                  <div>
                    <p className="label">Online:</p>
                    <p
                      className={cn(
                        "b2",
                        styles.onlineStatus,
                        styles[onlineStatus.toLowerCase().split(" ").join("")]
                      )}
                    >
                      {onlineStatus}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {linkData && (
          <div className={styles.buttonMobile}>
            <Button {...linkProps} variant="secondary" inheritColor>
              {linkData?.title}
            </Button>
          </div>
        )}
      </Grid>
    </li>
  );
};

export default ListItem;
