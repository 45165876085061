import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { SVG } from "~components";

import * as styles from "./styles.module.scss";

interface IProps {
  title: string;
  content: string;
  openByDefault?: boolean;
  className?: string;
}

const Accordion = ({ title, content, openByDefault, className }: IProps) => {
  const [isOpen, setIsOpen] = useState(openByDefault);

  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  const calculateContentHeight = () => {
    const height = contentRef?.current?.offsetHeight;
    setContentHeight(height);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(calculateContentHeight);
    if (contentRef?.current) {
      resizeObserver.observe(contentRef.current);
    }
    return () => {
      if (contentRef?.current) {
        resizeObserver.unobserve(contentRef.current);
      }
    };
  }, []);

  return (
    <div className={cn(styles.container, className)}>
      <button
        type="button"
        className={styles.button}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <h3 className="h3">{title}</h3>

        <SVG
          className={cn(styles.chevron, { [styles.isOpen]: isOpen })}
          svg="chevronDown"
        />
      </button>

      <div
        className={styles.contentContainer}
        style={{ height: `${isOpen ? contentHeight : 0}px` }}
      >
        <p ref={contentRef} className="b2">
          {content}
        </p>
      </div>
    </div>
  );
};

export default Accordion;
