// extracted by mini-css-extract-plugin
export var active = "styles-module--active--71106";
export var buttons = "styles-module--buttons--bd47e";
export var carousel = "styles-module--carousel--9ea38";
export var carouselContainer = "styles-module--carouselContainer--7bb93";
export var carouselSlide = "styles-module--carouselSlide--f6659";
export var carouselWrapper = "styles-module--carouselWrapper--8aa66";
export var container = "styles-module--container--920b3";
export var content = "styles-module--content--5cc80";
export var contentBody = "styles-module--contentBody--db6d0";
export var desktop = "1440px";
export var dot = "styles-module--dot--9ef45";
export var dots = "styles-module--dots--fd7f9";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var pressItem = "styles-module--pressItem--849d0";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var title = "styles-module--title--412c6";