import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import gsap from "gsap";
import { IAltImage, ISliceConfig } from "~schemas";
import { SliceConfig } from "~components";
import { GatsbyImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import { useBreakpoints } from "~hooks";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    heading: string;
    subheading: string;
    backgroundColor: object;
    image: IAltImage;
    tokenImage: IAltImage;
    sliceConfig: ISliceConfig;
    accordions: any;
  };
}

const HomepageAtf = ({
  data: { heading, subheading, image, tokenImage, accordions }
}: IProps) => {
  const { breakpoints } = useBreakpoints();
  const { ref, inView } = useInView({});
  const [openedAccordion, setOpenedAccordion] = useState<string | null>(
    accordions.find((accordion: any) => accordion.openByDefault)?._key
  );

  const isDesktop = breakpoints.desktop;
  const isTablet = breakpoints.tablet;

  // ---------------------------------------------------------------------------
  // ref / state

  const imageRef = useRef<HTMLDivElement>();

  const [pos, setPos] = useState({
    x: 0,
    y: 0,
    centerX: 0,
    centerY: 0
  });

  // ---------------------------------------------------------------------------
  // methods

  const handleMouseMove = (e: MouseEvent) => {
    if (typeof window === `undefined`) {
      return;
    }
    

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const centerX = e.clientX / viewportWidth - 0.5;
    const centerY = e.clientY / viewportHeight - 0.5;

    setPos({
      x: e.clientX,
      y: e.clientY,
      centerX,
      centerY
    });
  };

  const handleAccordionOpen = (accordionKey: string) => {
    if (openedAccordion === accordionKey) setOpenedAccordion(null);
    else setOpenedAccordion(accordionKey);
  }

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (typeof window === `undefined`) {
      return;
    }

    if (isDesktop) {
      window.addEventListener("mousemove", handleMouseMove, false);
    } else {
      window.removeEventListener("mousemove", handleMouseMove, false);

      if (imageRef?.current) {
        gsap.to(imageRef.current, {
          duration: 1,
          ease: `expo.out`,
          x: 0,
          y: 0
        });
      }
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove, false);
    };
  }, [imageRef, isDesktop]);

  useEffect(() => {
    if (!imageRef.current || !isDesktop) {
      return;
    }

    const { centerX, centerY } = pos;

    const x = centerX * 100;
    const y = centerY * 100;

    gsap.killTweensOf(imageRef.current);

    gsap.to(imageRef.current, {
      duration: 2,
      ease: `expo.out`,
      x,
      y
    });
  }, [imageRef, pos, breakpoints, isDesktop]);

  // ---------------------------------------------------------------------------
  // lifecycle

  return (
    <SliceConfig
      forwardRef={ref}
      className={cn(styles.container, { [styles.appear]: inView })}
    >
      <figure
        ref={imageRef}
        className={cn(styles.image, { [styles.appear]: inView })}
      >
        {image?.asset?.gatsbyImageData && (
          <GatsbyImage
            alt={image?.altText || "LUCIDAO"}
            image={image?.asset.gatsbyImageData}
          />
        )}
      </figure>

      <div className={styles.contentWrapper}>
        <div className={cn(styles.content, { [styles.appear]: inView })}>
          <h1 className={cn("d1", styles.heading)}>{heading}</h1>
          <p className={cn(isTablet ? `b1` : `b2`, styles.subheading)}>
            {subheading}
          </p>
        </div>
      </div>
    </SliceConfig>
  );
};

export default HomepageAtf;
