import React, { useEffect, useRef } from "react";
import cn from "classnames";
import gsap from "gsap";
import { Grid, SliceConfig, ParallaxImage } from "~components";
import { useBreakpoints } from "~hooks";
import {
  IAltImage,
  ILinkExternal,
  ILinkInternal,
  ISliceConfig
} from "~schemas";

import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    _key: string;
    eyebrow?: string;
    title?: string;
    body?: string;
    imageTouch?: IAltImage;
    image?: IAltImage;
    buttonLink: Array<ILinkExternal | ILinkInternal>;
    sliceConfig: ISliceConfig;
  };
}

const ParallaxArticle = ({
  data: { _key, eyebrow, title, body = "", imageTouch, image, sliceConfig }
}: IProps) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { breakpoints } = useBreakpoints();

  const isTablet = breakpoints.tablet;
  const isDesktop = breakpoints.smallDesktop;

  const imageMobile = imageTouch?.asset ? imageTouch : image;

  const imageData = isDesktop ? image : imageMobile;
  const paragraphs = body.trim().split("\n\n");

  // ---------------------------------------------------------------------------
  // ref / state

  const imageRef = useRef();

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!imageRef.current) {
      return;
    }

    const tl = gsap.timeline({ repeat: -1 });

    tl.to(imageRef.current, {
      duration: 4,
      y: 40,
      ease: `power2.inOut`
    });
    tl.to(imageRef.current, {
      duration: 4,
      y: 0,
      ease: `power2.inOut`
    });

    tl.play();
  }, [imageRef]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <Grid className={styles.grid}>
        <div className={styles.titles}>
          {eyebrow && <p className="label">{eyebrow}</p>}
          <h2 className={cn(isTablet ? `h1` : `d1`, styles.title)}>{title}</h2>
        </div>

        {imageData?.asset?.gatsbyImageData && (
          <div ref={imageRef} className={styles.image}>
            <ParallaxImage
              data={{
                image: imageData,
                parallax: {
                  drag: 1.25,
                  boundsPadding: 50,
                  xOffset: isTablet ? 24 : 16,
                  translateFactor: isTablet ? 12 : 20
                },
                loading: `eager`
              }}
            />
          </div>
        )}

        {paragraphs?.[0] && (
          <p className={cn("b1", styles.body)}>
            {paragraphs.map((text, index) => (
              <span key={`parallax-article-${_key}-paragraph-${index}`}>
                {text}
              </span>
            ))}
          </p>
        )}
      </Grid>
    </SliceConfig>
  );
};

export default ParallaxArticle;
