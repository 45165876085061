import React from "react";
import { Link, SVG, TSvg } from "~components";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IButton {
  children: React.ReactNode;
  variant?: "primary" | "secondary" | "text" | "caption";
  outline?: boolean;
  buttonType?: "button" | "reset" | "submit";
  to?: string;
  href?: string;
  iconLeft?: TSvg;
  iconRight?: TSvg;
  iconAppearFrom?: "right";
  color?: string;
  lightTheme?: boolean;
  disabled?: boolean;
  fluid?: boolean;
  loading?: boolean;
  fitContent?: boolean;
  inheritColor?: boolean;
  onClick?: () => void;
  className?: string;
}

const Button = ({
  children,
  variant = `primary`,
  outline,
  buttonType = `button`,
  to,
  href,
  iconLeft,
  iconRight,
  iconAppearFrom,
  color,
  lightTheme,
  disabled,
  fluid,
  loading,
  fitContent,
  inheritColor = false,
  onClick,
  className
}: IButton) => {
  let BtnElement: any;
  if (to) BtnElement = Link;
  else if (href) BtnElement = `a`;
  else BtnElement = `button`;

  return (
    <BtnElement
      type={to || href ? `` : buttonType}
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      disabled={disabled}
      onClick={() => onClick && onClick()}
      tabIndex={disabled ? `-1` : 0}
      to={to}
      style={{ backgroundColor: color }}
      className={cn(styles.button, className, styles[variant], {
        [styles.light]: lightTheme,
        [styles.fluid]: fluid,
        [styles.loading]: loading,
        [styles.disabled]: disabled,
        [styles.fitContent]: fitContent,
        [styles.outline]: outline,
        [styles.appear]: iconAppearFrom,
        [styles.inheritColor]: inheritColor
      })}
    >
      <div className={styles.content}>
        {iconLeft && (
          <SVG svg={iconLeft} className={cn(styles.icon, styles.left)} />
        )}
        <span
          className={cn([
            variant === `caption` ? `caption` : `button`,
            styles.buttonText
          ])}
        >
          {children}
        </span>
        {iconRight && (
          <SVG
            svg={iconRight}
            className={cn(styles.icon, styles.right, {
              [styles.appear]: iconAppearFrom === "right"
            })}
          />
        )}
      </div>
      <div className={styles.loaderContainer}>
        <div className={styles.loader} />
      </div>
    </BtnElement>
  );
};

export default Button;
