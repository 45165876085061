/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { useWindowDimensions } from "~hooks";

import fragmentShader from "./glsl/fragment";
import vertexShader from "./glsl/vertex";

const SpotlightMesh = ({
  dimensions,
  ringColor,
  edgeColor,
  backgroundColor
}) => {
  const { isDesktop } = useWindowDimensions();

  const circleRef = useRef();
  const mouseRef = useRef({ x: 0, y: 0 });
  const [targetPos, setTargetPos] = useState(new THREE.Vector3(0, 0, 0));

  const velocity = useRef(new THREE.Vector2(0, 0));

  const circleSize = dimensions.width * 1.2;

  const hexToRgb = (hex) => {
    const rgb = parseInt(hex.substring(1), 16);

    const r = Math.floor(rgb / 0x10000) % 0x100;
    const g = Math.floor(rgb / 0x100) % 0x100;
    const b = rgb % 0x100;

    return { r: r / 255, g: g / 255, b: b / 255 };
  };

  const ringColorRgb = hexToRgb(ringColor);
  const edgeColorRgb = hexToRgb(edgeColor);
  const backgroundColorRgb = hexToRgb(backgroundColor);

  const uniforms = {
    time: { value: 0 },
    resolution: {
      value: new THREE.Vector2(dimensions.width, dimensions.height)
    },
    velocity: { value: velocity.current },
    shaderColorRing: {
      value: new THREE.Color(ringColorRgb.r, ringColorRgb.g, ringColorRgb.b)
    },
    shaderColorEdge: {
      value: new THREE.Color(edgeColorRgb.r, edgeColorRgb.g, edgeColorRgb.b)
    },
    shaderBackground: {
      value: new THREE.Color(
        backgroundColorRgb.r,
        backgroundColorRgb.g,
        backgroundColorRgb.b
      )
    }
  };

  const material = new THREE.ShaderMaterial({
    uniforms,
    vertexShader,
    fragmentShader
  });

  useFrame(({ clock }) => {
    uniforms.time.value = clock.getElapsedTime();

    if (!isDesktop) {
      // Set the target position to the center of the canvas when not on desktop
      const centerPos = new THREE.Vector3(0, 0, 0);
      circleRef.current.position.lerp(centerPos, 1);
    } else {
      // The existing logic for updating the target position on desktop
      const x = (mouseRef.current.x / dimensions.width) * 2 - 1;
      const y = -(mouseRef.current.y / dimensions.height) * 2 + 1;
      const newTargetPos = new THREE.Vector3(
        x * (dimensions.width / 2),
        y * (dimensions.height / 2),
        0
      );

      setTargetPos((prevTargetPos) => prevTargetPos.lerp(newTargetPos, 0.05));
      circleRef.current.position.lerp(targetPos, 1);
    }
  });

  useEffect(() => {
    if (!isDesktop) {
      return () => {};
    }

    const handleMouseMove = (e) => {
      mouseRef.current.x = e.clientX;
      mouseRef.current.y = e.clientY;
    };

    window.addEventListener(`mousemove`, handleMouseMove);

    return () => {
      window.removeEventListener(`mousemove`, handleMouseMove);
    };
  }, [isDesktop]);

  return (
    <group>
      <group ref={circleRef}>
        <mesh frustumCulled={false} material={material}>
          <circleGeometry args={[circleSize, 128]} />
        </mesh>
      </group>
    </group>
  );
};

export default SpotlightMesh;
