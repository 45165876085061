import React, { useState } from "react";
import { IFaqTopic, ISliceConfig } from "~schemas";
import cn from "classnames";
import { Accordion, Button, Grid, SliceConfig } from "~components";

import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    faqs: Array<IFaqTopic>;
    sliceConfig?: ISliceConfig;
  };
}

const Faqs = ({ data: { faqs, sliceConfig } }: IProps) => {
  if (!faqs?.[0]) {
    return null;
  }

  faqs = [{_key: "all", faqs: [], title: "all"}, ...faqs];

  const [activeTopicKey, setActiveTopicKey] = useState(faqs?.[0]?._key);

  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <Grid>
        {/* mobile */}
        <div className={styles.faqsSmall}>
          {faqs.map(({ _key, title, faqs: topicFaqs }) => {
            return (
              <div key={`faqs-section-topic-small-${_key}`}>
                <p className={cn("button", styles.topicTitleSmall)}>{title}</p>

                <div className={styles.accordions}>
                  {topicFaqs?.[0] &&
                    topicFaqs.map(({ _key, answer, question }) => {
                      return (
                        <Accordion
                          key={`faqs-section-accordion-mobile-${_key}`}
                          title={question}
                          content={answer}
                        />
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>

        {/* tablet/desktop */}
        <div className={styles.faqsLarge}>
          <Grid fullWidth>
            <ul className={styles.topics}>
              {faqs.map(({ _key, title }) => {
                const isActive = _key === activeTopicKey;

                return (
                  <li key={`faqs-section-topic-large-${_key}`}>
                    <Button
                      className={cn(styles.topicSelector, {
                        [styles.active]: isActive
                      })}
                      variant="text"
                      lightTheme
                      onClick={() => setActiveTopicKey(_key)}
                    >
                      {title}
                    </Button>
                  </li>
                );
              })}
            </ul>

            <div className={styles.accordions}>
              {faqs.map(({ _key: topicKey, faqs: topicFaqs }) => {
                const isVisible = topicKey === activeTopicKey || activeTopicKey === "all";

                return (
                  <div
                    key={`faqs-section-accordions-mobile-${topicKey}`}
                    className={cn(styles.topicAccordions, {
                      [styles.visible]: isVisible
                    })}
                  >
                    {topicFaqs.map(({ _key, answer, question }) => {
                      return (
                        <Accordion
                          key={`faqs-section-accordion-mobile-${_key}`}
                          title={question}
                          content={answer}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </Grid>
        </div>
      </Grid>
    </SliceConfig>
  );
};

export default Faqs;
