import React from "react";
import cn from "classnames";
import { Button, Grid, SliceConfig } from "~components";
import { ILinkExternal, ILinkInternal, ISliceConfig } from "~schemas";
import { useBreakpoints } from "~hooks";
import { getButtonProps } from "~utils";

import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    text: string;
    buttonLink: Array<ILinkInternal | ILinkExternal>;
    sliceConfig?: ISliceConfig;
  };
}

const TextAndButton = ({ data: { text, buttonLink, sliceConfig } }: IProps) => {
  const { breakpoints } = useBreakpoints();

  const isTablet = breakpoints.tablet;

  const button = buttonLink?.[0];
  const buttonProps = getButtonProps(button);

  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <Grid>
        <div className={styles.content}>
          <h3 className={cn(isTablet ? `h2` : `h1`, styles.text)}>{text}</h3>

          {button?.title && (
            <Button {...buttonProps} variant="secondary" inheritColor>
              {button?.title}
            </Button>
          )}
        </div>
      </Grid>
    </SliceConfig>
  );
};

export default TextAndButton;
