import React from "react";
import cn from "classnames";
import { SVG } from "~components";
import * as styles from "./styles.module.scss";
import { IStat } from "~schemas";

interface IProps {
  data: IStat;
  className?: string;
}

const StatCard = ({ data, className }: IProps) => {
  const {
    mode,
    value,
    isPercentage,
    title,
    content,
    hoverGradientStroke: { colorOne, colorOneStop, colorTwo, colorTwoStop },
    hoverSpotlightColor
  } = data || {};

  return (
    <div className={cn(styles.container, styles[mode], className)}>
      <div
        className={cn(styles.border)}
        style={{
          background: `linear-gradient(0deg, ${colorOne?.hex} ${colorOneStop}%, ${colorTwo?.hex} ${colorTwoStop}%) border-box`
        }}
      />

      <figure
        className={styles.hoverColor}
        style={{ color: `${hoverSpotlightColor?.hex || `var(--color-green)`}` }}
      >
        <SVG svg="hoverEllipse" />
      </figure>

      <div>
        {value && (
          <p className={cn("d1", styles.value)}>
            {value}
            {isPercentage && `%`}
          </p>
        )}
        {title && <h4 className={cn("h3", styles.title)}>{title}</h4>}
      </div>

      {content && <p className={cn("label", styles.content)}>{content}</p>}
    </div>
  );
};

export default StatCard;
