import React, { useEffect, useState } from "react";
import { IAltImage, ILinkExternal, ILinkInternal, ISliceConfig, IVideo } from "~schemas";
import Media from "../Media";
import useEmblaCarousel from "embla-carousel-react";

import * as styles from "./styles.module.scss";
import { Button, SVG } from "~components";

interface IProps {
  data: {
    medias: {
      _key: string;
      eyebrow?: string;
      title?: string;
      body?: string;
      buttonLink: Array<ILinkExternal | ILinkInternal>;
      media: Array<IAltImage | IVideo>;
      sliceConfig?: ISliceConfig;
    }[]
  }
}

const MediaCarousel = ({ data: { medias } }: IProps) => {
  const [carouselRef, carouselApi] = useEmblaCarousel({ loop: true, slidesToScroll: 1, align: 'start' });

  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    if (carouselApi) {
      const updateCarousel = (api: typeof carouselApi) => {
        const scrollablePrev = api.canScrollPrev();
        const scrollableNext = api.canScrollNext();

        setCanScrollNext(scrollableNext);
        setCanScrollPrev(scrollablePrev);
        setIsScrollable(scrollableNext || scrollablePrev);
      };

      carouselApi.on(`init`, updateCarousel);

      carouselApi.on(`reInit`, updateCarousel);

      carouselApi.on(`resize`, updateCarousel);

      carouselApi.on(`scroll`, updateCarousel);

      updateCarousel(carouselApi);
    }
  }, [carouselApi]);

  return (
    <div className={styles.container}>
      <div className={styles.carouselWrapper}>
        <div className={styles.carousel} ref={carouselRef}>
          <ul className={styles.carouselContainer}>
            {medias.map((media) => (
              <li key={media._key} className={styles.carouselSlide}>
                <Media key={media._key} data={media} isInCarousel />
              </li>
            ))}
          </ul>
        </div>
      </div>
      {!!isScrollable && (
        <div className={styles.buttons}>
          <Button variant="secondary" disabled={!canScrollPrev} onClick={() => carouselApi?.scrollPrev()}>
            <SVG svg="arrowLeft" />
          </Button>
          <Button variant="secondary" disabled={!canScrollNext} onClick={() => carouselApi?.scrollNext()}>
            <SVG svg="arrowRight" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default MediaCarousel;
