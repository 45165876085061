import React from "react";
import cn from "classnames";
import { Link } from "~components";
import * as styles from "./styles.module.scss";

interface IProps {
  to: string;
  children: React.ReactNode;
  lightTheme?: boolean;
  active: boolean;
}

const Tab = ({ to, children, lightTheme = false, active = false }: IProps) => {
  return (
    <Link
      className={cn(styles.container, {
        [styles.active]: active,
        [styles.light]: lightTheme
      })}
      to={to}
    >
      <span className="button">{children}</span>
    </Link>
  );
};

export default Tab;
