// extracted by mini-css-extract-plugin
export var body = "styles-module--body--4c661";
export var bodyWrapper = "styles-module--bodyWrapper--911a9";
export var buttonBody = "styles-module--buttonBody--b937a";
export var buttonDesktop = "styles-module--buttonDesktop--1a2b5";
export var buttonTablet = "styles-module--buttonTablet--d8989";
export var container = "styles-module--container--1a170";
export var desktop = "1440px";
export var eyebrow = "styles-module--eyebrow--b58f1";
export var giant = "2200px";
export var grid = "styles-module--grid--53379";
export var inCarousel = "styles-module--inCarousel--4c0b5";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--b772b";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var titleWrapper = "styles-module--titleWrapper--edcf3";