// extracted by mini-css-extract-plugin
export var body = "styles-module--body--ea805";
export var container = "styles-module--container--8e2c7";
export var desktop = "1440px";
export var eyebrow = "styles-module--eyebrow--52d2d";
export var giant = "2200px";
export var items = "styles-module--items--d9d5c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var title = "styles-module--title--5b521";