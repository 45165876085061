import React from "react";
import cn from "classnames";
import { ILinkExternal, ILinkInternal, ISliceConfig } from "~schemas";
import { Button, Grid, SliceConfig } from "~components";
import { getButtonProps } from "~utils";

import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    title: string;
    body: string;
    buttonLink: Array<ILinkExternal | ILinkInternal>;
    sliceConfig: ISliceConfig;
  };
}

const ComplexTextLockup = ({
  data: { title, body, buttonLink, sliceConfig }
}: IProps) => {
  const button = buttonLink?.[0];
  const buttonProps = getButtonProps(button);

  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <Grid>
        {title && <h1 className={cn("h1", styles.title)}>{title}</h1>}

        {(body || button?.title) && (
          <div className={styles.bodyContainer}>
            {body && <p className={cn("b2", styles.body)}>{body}</p>}

            {button?.title && (
              <Button
                className={styles.button}
                variant="secondary"
                inheritColor
                {...buttonProps}
              >
                {button.title}
              </Button>
            )}
          </div>
        )}
      </Grid>
    </SliceConfig>
  );
};

export default ComplexTextLockup;
