// extracted by mini-css-extract-plugin
export var buttons = "styles-module--buttons--d5d91";
export var carousel = "styles-module--carousel--1af35";
export var carouselContainer = "styles-module--carouselContainer--60d12";
export var carouselSlide = "styles-module--carouselSlide--f3f7b";
export var carouselWrapper = "styles-module--carouselWrapper--d70c8";
export var container = "styles-module--container--752f5";
export var desktop = "1440px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";