import React, { createContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { globalHistory } from "@reach/router";

export interface IAppContext {
  pathname: string;
}

export const AppContext = createContext<IAppContext>({} as IAppContext);

interface IProps {
  children: React.ReactNode;
}

const AppProvider = ({ children }: IProps) => {
  const [pathname, setPathname] = useState(``);

  useEffect(() => {
    if (typeof window !== `undefined` && window?.location?.pathname) {
      setPathname(window.location.pathname);
    }

    return globalHistory.listen(({ location }) => {
      setPathname(location.pathname);
    });
  }, []);

  // ---------------------------------------------------------------------------
  // render

  const contextProps = useMemo(
    () => ({
      pathname
    }),
    [pathname]
  );

  return (
    <AppContext.Provider value={contextProps}>{children}</AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
