import React from "react";
import cn from "classnames";
import { SVG, TSvg } from "~components";

import * as styles from "./styles.module.scss";

interface IProps {
  title: string;
  newWindow: boolean;
  url: string;
  className?: string;
}

const SocialLink = ({ title, newWindow, url, className }: IProps) => (
  <a
    className={cn(styles.container, className)}
    href={url}
    target={newWindow ? `_blank` : `_self`}
    title={title}
    aria-label={title}
  >
    <SVG svg={title.toLowerCase()} />
  </a>
);
export default SocialLink;
