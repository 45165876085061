import React from "react";
import { ISliceConfig } from "~schemas";

interface IProps {
  children: React.ReactNode;
  config?: ISliceConfig;
  className?: string;
  forwardRef?: React.Ref<HTMLDivElement>;
}

const SliceConfig = ({ children, config, className, forwardRef }: IProps) => {
  const { backgroundColor, textColor } = config || {};
  const colorStyles = {
    background: `var(--color-navy)`,
    color: `var(--color-white)`
  };

  if (textColor?.hex) {
    colorStyles.color = textColor.hex;
  }

  if (backgroundColor) {
    const { _type, colorOne, colorOneStop, colorTwo, colorTwoStop, hex } =
      backgroundColor;

    colorStyles.background =
      _type === "gradient"
        ? `linear-gradient(0deg, ${colorOne?.hex} ${colorOneStop}%, ${colorTwo?.hex} ${colorTwoStop}%)`
        : hex;
  }

  return (
    <section ref={forwardRef} style={colorStyles} className={className}>
      {children}
    </section>
  );
};

export default SliceConfig;
