import React from "react";
import cn from "classnames";
import { Link as GatsbyLink } from "gatsby";

import * as styles from "./styles.module.scss";

interface IProps {
  children?: React.ReactNode;
  activeClassName?: string;
  className?: string;
  disable?: boolean;
  to?: string;
  title?: string;
  target?: string;
  tabIndex?: number;
  ariaLabel?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  onMouseOver?: React.MouseEventHandler<HTMLAnchorElement>;
  onMouseOut?: React.MouseEventHandler<HTMLAnchorElement>;
}

const Link = ({
  children,
  activeClassName,
  className,
  disable,
  to,
  title = "",
  target = `_blank`,
  tabIndex,
  ariaLabel,
  onClick = () => {},
  onMouseOver = () => {},
  onMouseOut = () => {}
}: IProps) => {
  const isExternal = to && to.match(/(http|#|tel:|mailto:)/);
  const siteUrl = process.env.GATSBY_SITE_URL;
  const containsSiteUrl = siteUrl && to && to.indexOf(siteUrl) !== -1;

  if (isExternal && !containsSiteUrl) {
    return (
      <a
        href={to || undefined}
        className={cn(className, { [styles.disabled]: disable })}
        title={title}
        target={target}
        onClick={onClick}
        rel="nofollow noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  const isIndex = to === "//";

  const withTrailingSlash = to
    ? `${to}${to.endsWith(`/`) || to.includes(`?`) ? `` : `/`}`
    : "";

  const linkTo = containsSiteUrl
    ? withTrailingSlash.replace(siteUrl, ``)
    : withTrailingSlash;

  return (
    <GatsbyLink
      to={isIndex ? `/` : linkTo}
      aria-label={ariaLabel || undefined}
      activeClassName={activeClassName}
      className={cn(className, {
        [styles.disabled]: disable
      })}
      title={title}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      tabIndex={tabIndex}
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;
