import React from "react";
import cn from "classnames";
import { ILinkExternal, ILinkInternal, ISliceConfig } from "~schemas";
import { Button, BlurCard, Grid, SliceConfig } from "~components";
import { IBlurCard } from "src/components/Common/BlurCard";
import { getButtonProps } from "~utils";

import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    eyebrow: string;
    title: string;
    body: string;
    buttonLink: Array<ILinkExternal | ILinkInternal>;
    cards: Array<IBlurCard>;
    sliceConfig?: ISliceConfig;
  };
}

const Governance = ({
  data: { eyebrow, title, body, buttonLink, cards, sliceConfig }
}: IProps) => {
  const button = buttonLink?.[0];
  const buttonProps = getButtonProps(button);

  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <Grid className={styles.grid}>
        {eyebrow && <p className={cn("label", styles.eyebrow)}>{eyebrow}</p>}

        {title && (
          <div className={styles.titleWrapper}>
            <h3 className="d1">{title}</h3>

            {button?.title && (
              <Button
                className={styles.buttonTablet}
                {...buttonProps}
                variant="secondary"
                inheritColor
              >
                {button.title}
              </Button>
            )}
          </div>
        )}

        <div className={styles.bodyWrapper}>
          {body && <p className={cn("b1", styles.body)}>{body}</p>}

          {button?.title && (
            <Button
              className={styles.buttonBody}
              {...buttonProps}
              variant="secondary"
              inheritColor
            >
              {button.title}
            </Button>
          )}
        </div>

        {cards?.[0] && (
          <div className={styles.cards}>
            {cards.map((card) => {
              return (
                <BlurCard
                  key={`section-governance-card-${card?._key}`}
                  className={styles.blurCard}
                  data={card}
                />
              );
            })}
          </div>
        )}
      </Grid>
    </SliceConfig>
  );
};

export default Governance;
