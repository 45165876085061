import { useState, useEffect } from "react";
import * as bp from "~styles/breakpoints.module.scss";

// ... (rest of your types and constants)

const useBreakpoints = () => {
  const bpArray = Object.keys(bp);

  const parseBpStringToInt = (bpStringWithPx: string) =>
    parseInt(bpStringWithPx.replace(`px`, ``));

  const [breakpoints, setBreakpoints] = useState<TBreakpoints>({});

  useEffect(() => {
    // This function now resides inside the useEffect to ensure it's only called client-side.
    const getInitialBreakpoints = () => {
      const initialBreakpoints: TBreakpoints = {};

      for (let i = 0; i < bpArray.length; i += 1) {
        initialBreakpoints[bpArray[i] as TBreakpoint] =
          window.innerWidth >= parseBpStringToInt(bp[bpArray[i]]);
      }

      return initialBreakpoints;
    };

    // Set initial state here
    setBreakpoints(getInitialBreakpoints());

    const handleResize = () => {
      const updatedBreakpoints: TBreakpoints = {};

      for (let i = 0; i < bpArray.length; i += 1) {
        updatedBreakpoints[bpArray[i] as TBreakpoint] =
          window.innerWidth >= parseBpStringToInt(bp[bpArray[i]]);
      }

      setBreakpoints(updatedBreakpoints);
    };

    window.addEventListener(`resize`, handleResize);
    return () => window.removeEventListener(`resize`, handleResize);
  }, []);

  return {
    breakpoints
  };
};

export default useBreakpoints;
