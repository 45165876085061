// extracted by mini-css-extract-plugin
export var body = "styles-module--body--facb3";
export var container = "styles-module--container--4a1a3";
export var desktop = "1440px";
export var eyebrow = "styles-module--eyebrow--9721d";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var stat = "styles-module--stat--1e24b";
export var stats = "styles-module--stats--92408";
export var tablet = "768px";
export var title = "styles-module--title--e0209";