import React, { useEffect, useRef } from "react";
import cn from "classnames";
import gsap from "gsap";
import {
  IAltImage,
  ILinkExternal,
  ILinkInternal,
  ISliceConfig
} from "~schemas";
import { Button, ParallaxImage, Grid, SliceConfig } from "~components";
import { useBreakpoints } from "~hooks";
import { getButtonProps } from "~utils";

import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    eyebrow?: string;
    title?: string;
    subtitle?: string;
    body?: string;
    buttonLink: Array<ILinkExternal | ILinkInternal>;
    image: IAltImage;
    sliceConfig: ISliceConfig;
  };
}

const HalfBlurAtf = ({
  data: { eyebrow, title, subtitle, body, buttonLink, image, sliceConfig }
}: IProps) => {
  const { breakpoints } = useBreakpoints();
  const isTablet = breakpoints.tablet;
  const isDesktop = breakpoints.desktop;

  // ---------------------------------------------------------------------------
  // ref / state

  const imageRef = useRef<HTMLDivElement>(null);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!imageRef.current || isDesktop) {
      return;
    }

    const tl = gsap.timeline({ repeat: -1 });

    tl.to(imageRef.current, {
      duration: 4,
      y: 30,
      ease: `power2.inOut`
    });
    tl.to(imageRef.current, {
      duration: 4,
      y: 0,
      ease: `power2.inOut`
    });

    tl.play();
  }, [imageRef, isDesktop]);

  return (
    <SliceConfig className={styles.container} config={sliceConfig}>
      <div ref={imageRef} className={styles.imageContainer}>
        {image?.asset?.gatsbyImageData && (
          <ParallaxImage
            className={styles.image}
            data={{
              image: image,
              imageStyle: {
                width: `100%`,
                height: `100%`,
                objectFit: `contain`
              },
              parallax: {
                drag: 1.25,
                boundsPadding: 50,
                translateFactor: isTablet ? 12 : 8
              }
            }}
          />
        )}
      </div>

      <div className={styles.gridContainer}>
        <Grid className={styles.grid}>
          {eyebrow && <p className={cn("label", styles.eyebrow)}>{eyebrow}</p>}

          {title && <h1 className={cn("d1", styles.title)}>{title}</h1>}

          {subtitle && <h3 className={cn("h3", styles.subtitle)}>{subtitle}</h3>}

          {(body || !!buttonLink?.length) && (
            <div className={styles.bodyContainer}>
              {body && <p className={cn("b2", styles.body)}>{body}</p>}

              {!!buttonLink?.length && (
                <div className={styles.buttonContainer}>
                  {buttonLink.map((button) => {
                    const props = getButtonProps(button);

                    return (
                      <Button
                        key={button._key}
                        {...props}
                        variant="secondary"
                        inheritColor
                      >
                        {button?.title}
                      </Button>
                    )
                  })}
                </div>
              )}
            </div>
          )}
        </Grid>
      </div>
    </SliceConfig>
  );
};

export default HalfBlurAtf;
