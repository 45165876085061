// extracted by mini-css-extract-plugin
export var body = "styles-module--body--e34c8";
export var bodyContainer = "styles-module--bodyContainer--39877";
export var button = "styles-module--button--b6779";
export var container = "styles-module--container--b18d9";
export var desktop = "1440px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var title = "styles-module--title--e8a80";