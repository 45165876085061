// extracted by mini-css-extract-plugin
export var border = "styles-module--border--b4b38";
export var container = "styles-module--container--1f31e";
export var content = "styles-module--content--b80d2";
export var dark = "styles-module--dark--9f806";
export var desktop = "1440px";
export var giant = "2200px";
export var hoverColor = "styles-module--hoverColor--430b9";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--6f033";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var title = "styles-module--title--13213";
export var value = "styles-module--value--faf84";