import { useState, useEffect } from "react";

const useWindowDimensions = () => {
  const [screen, setScreen] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  //

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener(`resize`, handleResize);

    handleResize();

    return () => window.removeEventListener(`resize`, handleResize);
  }, []);

  //

  useEffect(() => {
    if (!windowSize?.width || !windowSize?.height) {
      return;
    }

    let detectedScreen = `xs`;

    if (windowSize?.width > 768) {
      detectedScreen = `sm`;
    }

    if (windowSize?.width > 1024) {
      detectedScreen = `md`;
    }

    if (windowSize?.width > 1280) {
      detectedScreen = `lg`;
    }

    if (windowSize?.width > 1440) {
      detectedScreen = `xl`;
    }

    if (windowSize?.width > 1920) {
      detectedScreen = `xxl`;
    }

    setScreen(detectedScreen);
  }, [windowSize]);

  //

  return {
    isDesktop: windowSize?.width > 1024,
    isMobile: windowSize?.width < 768,
    screen,
    windowSize
  };
};

export default useWindowDimensions;
